<template>
  <div class="company_profile">
    <subtitle>
      <div slot="above-title">公司简介</div>
    </subtitle>
    <div class="profile_content">
      <div class="content_text">
        <p>
          深圳大富智慧健康科技有限公司是大富科技（股票：300134）旗下的全资子公司，是一家专注于辅助中医科技创新、健康智能化升级的高新技术企业。
        </p>
        <p>
          公司将以智能硬件、云服务、AI智能系统为核心，打造业界领先的中医相关养生健康管理和大数据解决方案，形成智慧健康产业链。
        </p>
        <p>
          大富智慧健康立足传统医学理论，结合现代科技，致力于为中国传统医药学找到解决教授难、普及难、传播难的根本方法。大富智慧健康隶属上市公司大富科技集团，其在健康养生、人工智能、智能化设备等领域深耕数十年。在集团孙尚传董事长精诚报国、力根本源、普惠万家核心理念的指引和激励下，大富智慧健康必将成为建立人类命运共同体的重要构成！
        </p>
      </div>
      <div class="content_img">
        <img src="~assets/img/common/company_photo.png" alt="" />
      </div>
    </div>
    <!-- <div style="width: 1150px; margin: 0 auto">
      <img src="~assets/img/home/profile.png" width="100%" alt="" />
    </div> -->
  </div>
</template>

<script>
import Subtitle from 'components/content/subtitle/Subtitle'

export default {
  name: 'CompanyProfile',
  components: {
    Subtitle
  },
  methods: {}
}
</script>

<style scoped>
.company_profile {
  padding: 50px 0;
  /* width: 100%; */
}

.profile_content {
  display: flex;
  justify-content: center;
  margin: 50px 0 60px;
}

.content_text {
  width: 560px;
  line-height: 28px;
  overflow: hidden;
  text-align: left;
  font-size: 18px;
  color: #555555;
}
.content_text p {
  text-indent: 2em;
  margin-bottom: 20px;
}

.content_img {
  border: 2px solid #ebebeb;
  padding: 10px;
  margin-left: 20px;
  width: 580px;
}
</style>
