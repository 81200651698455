<template>
  <div class="about">
    <company-profile />
  </div>
</template>

<script>
import CompanyProfile from '../../components/common/companyProfile.vue'
export default {
  name: 'About',
  components: {
    CompanyProfile
  }
}
</script>

<style scoped>
.about {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}
</style>
